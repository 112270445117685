import React, { useEffect, useRef, useState } from "react"
import Link from "gatsby-plugin-transition-link"
import classnames from "classnames/bind"
import { motion } from "framer-motion"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

// Style
import marqueeStyle from "../style/components/marquee.module.scss"

const MarqueeTitle = props => {
  // textWrapperRef Array
  const textWrapperRef = useRef([])
  textWrapperRef.current = []

  useEffect(() => {
    textWrapperRef.current.forEach((el, index) => {
      // odd children and even children go different directions
      const windowOffsetWidth = document.documentElement.clientWidth
      const [x, xEnd] = index % 2 ? ["-30%", "-40%"] : ["-60%", "-50%"]

      // ? ["100%", (el.scrollWidth - windowOffsetWidth) * -1]
      // : [el.scrollWidth * -1, el.scrollWidth * -0.1]

      gsap.fromTo(
        el,
        { x },
        {
          x: xEnd,
          immediateRender: false,
          scrollTrigger: {
            id: "marqueeTitleMove",
            trigger: el,
            scrub: 0.5,
            markers: false,
          },
        }
      )
    })
  }, [])

  // Add children to textWrapperRef Array
  const addToRefs = el => {
    if (el && !textWrapperRef.current.includes(el)) {
      textWrapperRef.current.push(el)
    }
  }

  return pug`
    div(className=classnames(props.className,marqueeStyle.rotating_text_wrapper))
      each val1,index1 in [1,2]
        div(className=marqueeStyle.marquee_row  ref=addToRefs)
          div.flex.flex-no-wrap(className=marqueeStyle.marquee_wrapper)
            each val2,index2 in [1,2,3,4]
              h1.MarqueeTitleText.inline-block.uppercase.italic.ml-8(className=classnames(marqueeStyle.marquee_text, index1 % 2 ?'txt-hasBorder-white-dark':props.color))=props.title
  `
}
const MarqueeTitleThree = props => {
  // textWrapperRef Array
  const textWrapperRef = useRef([])
  textWrapperRef.current = []

  useEffect(() => {
    textWrapperRef.current.forEach((el, index) => {
      // odd children and even children go different directions
      const windowOffsetWidth = document.documentElement.clientWidth
      const [x, xEnd] = index % 2 ? ["-20%", "-30%"] : ["-50%", "-40%"]

      // ? ["100%", (el.scrollWidth - windowOffsetWidth) * -1]
      // : [el.scrollWidth * -1, el.scrollWidth * -0.1]

      gsap.fromTo(
        el,
        { x },
        {
          x: xEnd,
          immediateRender: false,
          scrollTrigger: {
            id: "marqueeTitleMove",
            trigger: el,
            scrub: 0.5,
            markers: false,
          },
        }
      )
    })
  }, [])

  // Add children to textWrapperRef Array
  const addToRefs = el => {
    if (el && !textWrapperRef.current.includes(el)) {
      textWrapperRef.current.push(el)
    }
  }

  return pug`
    div.relative(className=classnames(marqueeStyle.wrapper,marqueeStyle.three,props.className))
      div(className=classnames(props.className,marqueeStyle.rotating_text_wrapper))
        each val1,index1 in [1,2,3]
          div(className=marqueeStyle.marquee_row  ref=addToRefs)
            div.flex.flex-no-wrap(className=marqueeStyle.marquee_wrapper)
              each val2,index2 in [1,2,3,4]
                h1.inline-block.uppercase.italic.ml-8(className=classnames(marqueeStyle.marquee_text, index1 % 2 ?'txt-hasBorder-dark-white':props.color))=props.title
  `
}

export { MarqueeTitle, MarqueeTitleThree }
