import React from "react"
import classnames from "classnames/bind"
// Component
import SpotifyPlayer from "../../components/about/spotify"
import {
  OneColumnHeading,
  OneColumnTitle,
} from "../../components/project/one-column"
import {
  FullExperienceBlock,
  ColumnExperienceBlock,
} from "../../components/about/experience-block"
import { ProjectContainer } from "../../layouts/container"
// Style
import experienceStyle from "../../style/section/aboutpage/experience.module.scss"
// Data
import useAboutpageData from "../../hook/aboutpage-data"

function AboutPageExperience(props) {
  const fakeData = {
    num: "01",
    heading: "SWEET ROLL",
    title: "Cake tiramisu cake apple",
    content:
      "Chocolate bar cheesecake toffee tiramisu halvah cake. Soufflé tiramisu soufflé sweet roll toffee sugar plum jelly beans chocolate. Sweet roll donut apple pie. Jelly I love jelly.",
  }
  const fakeExperienceData = {
    name: "Cake tiramisu cake apple",
    title: "SUGAR PLUM CHUPA",
    content: `Chocolate bar cheesecake toffee tiramisu halvah cake. Soufflé tiramisu soufflé sweet roll toffee sugar plum jelly beans.`,
    shortContent: `Chocolate bar cheesecake toffee`,
    date: "July 2019 - June 2020",
  }
  const {
    overview,
    experience_title,
    experience_group,
    experience_intro,
    awards_group,
    awards_title,
    selected_project_group,
    selected_project_title,
  } = useAboutpageData()

  return pug`
    div.relative(className=experienceStyle.wrapper)
      div.flex.justify-center.items-center.flex-col
        div.flex.items-start.justify-start.flex-wrap.col-12.col-lg-10.px-0
          // Brief
          div.flex.items-start.justify-start.flex-wrap.px-0
            p.text-white.text-xl.font-semibold.mb-4(className="md:text-2x")=overview.raw[0].text
            p.text-white.text-xl.font-semibold.mb-10(className="md:text-2x")=overview.raw[1].text
            p.text-theme-200.text-xl=overview.raw[2].text

          // Experience
          OneColumnHeading.mb-16(heading=experience_title.text content=experience_intro.text darkmode=true)
          div.flex.items-center.justify-center
            div.flex.items-start.justify-start.flex-wrap.col-12.px-0
              each item,index in experience_group
                FullExperienceBlock(name=item.name.text title=item.title.text date=item.date_and_local.text content=item.content.text bgColor="bg-primary-main" icon="icon" fluid=item.icon.fluid)

          // Selected Work
          div.flex.items-center.justify-center.mt-20
            div.flex.items-start.justify-start.flex-wrap.col-12.px-0
              h5.text-3x.text-white.font-bold.uppercase.mb-6=selected_project_title.text
              each item,index in selected_project_group
                FullExperienceBlock(name=item.name1.text title=item.title.text date=item.date_and_local.text content=item.content.text bgColor="bg-primary-light1" icon="briefcase")

          // Awards
          div.flex.items-center.justify-center.mt-20
            div.flex.items-start.justify-start.flex-wrap.col-12.px-0
              h5.text-3x.text-white.font-bold.uppercase.mb-6=awards_title.text
              each item,index in awards_group
                FullExperienceBlock(name=item.name.text date=item.date_and_local.text title=item.title.text bgColor="bg-secondary-main" icon="trophy")

          // OneColumnTitle.mb-16(num=fakeData.num heading=fakeData.heading content=fakeData.content darkmode=true)
          // div.flex.items-start.justify-center.row
          //   div.flex.items-start.justify-start.flex-wrap.col-12.col-lg-6
          //     h5.text-base.text-white.font-bold.uppercase.mb-12=selected_project_title.text
          //     each item,index in selected_project_group
          //       ColumnExperienceBlock(name=item.name1.text title=item.title.text date=item.date_and_local.text content=item.content.text bgColor="bg-primary-light1" icon="briefcase")
          //   div.flex.items-start.justify-start.flex-wrap.col-12.col-lg-6
          //     h5.text-base.text-white.font-bold.uppercase.mb-12=awards_title.text
          //     each item,index in awards_group
          //       ColumnExperienceBlock(name=item.name.text date=item.date_and_local.text title=item.title.text bgColor="bg-secondary-main" icon="trophy")
  `
}

export default AboutPageExperience
