import React from "react"
import classnames from "classnames/bind"
// Component
import { OneColumnHeading } from "../../components/project/one-column"
import ScrollBox from "../../components/about/scroll-box"
import { EnjoyImageGrid } from "../../components/about/image-grid"
// Date
import useAboutpageData from "../../hook/aboutpage-data"
// Style
import oneColumnStyle from "../../style/components/projects/one-column.module.scss"
import experienceStyle from "../../style/section/aboutpage/experience.module.scss"

function AboutpageHobbies(props) {
  const { enjoy_image, body } = useAboutpageData()
  const lifeData = {
    hobbiesTitle: body[0].items[1].title.text,
    hobbiesContent: body[0].items[1].content.text,
  }

  return pug`
    div.relative(className=experienceStyle.wrapper)
      div.flex.justify-center.items-center.flex-col
        div.flex.items-start.justify-start.flex-wrap.col-12.col-lg-10.px-0
          OneColumnHeading.mb-16(heading=lifeData.hobbiesTitle content=lifeData.hobbiesContent darkmode=true)
          p.text-base.text-theme-200.mb-4 Recently enjoy:
      ScrollBox
        each item, index in enjoy_image
          EnjoyImageGrid(key="enjoyImageGrid-item-"+index fluid=item.enjoy_image1.fluid image_alt=item.enjoy_image1.alt)

  `
}

export default AboutpageHobbies
