import React from "react"
import Img from "gatsby-image"
import classnames from "classnames/bind"
// Style
import oneColumnStyle from "../../style/components/projects/one-column.module.scss"

// 獨立自己一區的完整內容
const OneColumnHeading = props => {
  return pug`
    div.flex.justify-center.items-center.flex-col(className=classnames(oneColumnStyle.wrapper, props.className))
      if (props.num)
        div.col-12.mb-1.px-0(className=oneColumnStyle.subtitle)
          span(className=props.darkmode?"text-theme-300":"text-gray-100")=props.num
      if (props.heading)
        div.col-12.mb-8.px-0(className=oneColumnStyle.heading)
          span(className=props.darkmode?"text-theme-100":"text-gray-100")=props.heading
      if (props.title)
        div.col-12.mb-4.px-0(className=oneColumnStyle.title)
          span(className=props.darkmode?"text-theme-100":"text-gray-100")=props.title
      if (props.content)
        div.col-12.px-0(className=oneColumnStyle.content)
          p(className=props.darkmode?"text-theme-200":"text-gray-200")=props.content
      ${props.children}
  `
}

// 獨立自己一區的title和content
const OneColumnTitle = props => {
  return pug`
    div.flex.justify-center.items-center.flex-col(className=classnames(oneColumnStyle.wrapper_title, props.className))
      if (props.title)
        div.col-12.mb-4.px-0(className=oneColumnStyle.title)
          span(className=props.darkmode?"text-theme-100":"text-gray-100")=props.title
      if (props.content)
        div.col-12.px-0(className=oneColumnStyle.content)
          p(className=props.darkmode?"text-theme-200":"text-gray-200")=props.content
      ${props.children}
  `
}

// 只有單單title和content，用來家在OneColumnHeading下
const OneColumnElementTitle = props => {
  return pug`
    if (props.title)
      div.col-12.mb-4.px-0(className=classnames(oneColumnStyle.title, props.title?props.className:''))
        span(className=props.darkmode?"text-theme-100":"text-gray-100")=props.title
    if (props.content)
      div.col-12.px-0(className=classnames(oneColumnStyle.content, props.title?'':props.className))
        p(className=props.darkmode?"text-theme-200":"text-gray-200")=props.content
  `
}

const OneColumnElementUnorderList = props => {
  return pug`
    ul.col-12.list-disc.mt-4.ml-8(className="md:ml-16")
      ${props.children}
  `
}

const OneColumnElementOrderList = props => {
  return pug`
    ol.col-12.list-decimal.mt-4.ml-8(className="md:ml-16")
      ${props.children}
  `
}

const OneColumnElementListItem = props => {
  return pug`
    li.mb-12
      div.mb-2(className=oneColumnStyle.content)
        span.font-semibold(className=props.darkmode?"text-theme-100":"text-gray-100")=props.title
      div(className=oneColumnStyle.content)
        p(className=props.darkmode?"text-theme-200":"text-gray-200")=props.content
  `
}

export {
  OneColumnHeading,
  OneColumnTitle,
  OneColumnElementTitle,
  OneColumnElementUnorderList,
  OneColumnElementOrderList,
  OneColumnElementListItem,
}
