import React, { useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// Component
import LayoutWrapper from "../layouts/wrapper"
import Metadata from "../layouts/metadata"
import Header from "../layouts/header"
import { MainContainer } from "../layouts/container"
import Footer from "../layouts/footer"
import { MarqueeTitleThree } from "../components/marquee"
import Social from "../layouts/social"
// Section
import AboutPageHero from "../section/aboutpage/aboutpage-hero"
import AboutPagePlaylist from "../section/aboutpage/aboutpage-playlist"
import AboutPageExperience from "../section/aboutpage/aboutpage-experience"
import AboutPageLife from "../section/aboutpage/aboutpage-life"
import AboutpageHobbies from "../section/aboutpage/aboutpage-hobbies"
// Style

export default function Aboutpage() {
  return pug`
    Metadata(title="About")
    LayoutWrapper.bg-gray-100.darkMode
      Header(darkmode=true)
      Social(darkmode=true)
      AboutPageHero
      MainContainer
        AboutPageExperience
        div.relative
          MarqueeTitleThree(title="me myself and I" color="txt-hasShadow-white-white")
        AboutPageLife
        AboutpageHobbies
        AboutPagePlaylist
      Footer
  `
}
