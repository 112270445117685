import React from "react"
import classnames from "classnames/bind"

// Component
import { Container } from "../../layouts/container"
import Divider from "../../components/divider"
import Landing from "../../components/landing"

// Style
import heroStyle from "../../style/section/aboutpage/hero.module.scss"

const AboutPageHero = props => {
  return pug`
    section(className=heroStyle.hero_wrapper)
      Container.flex.justify-center.items-center.flex-col
        div.flex.justify-start.flex-col.col-12.col-lg-10.px-0(className=classnames('md:justify-between','md:flex-row'))
          Landing(darkmode=true)
        div.col-8.px-0.mt-10
          Divider.bg-white.mb-0
  `
}
export default AboutPageHero
