import React from "react"
import classnames from "classnames/bind"
// Component
import { ImageGrid } from "../../components/about/image-grid"
// Style
import oneColumnStyle from "../../style/components/projects/one-column.module.scss"
// Data
import useAboutpageData from "../../hook/aboutpage-data"

function AboutPageLife(props) {
  const { body } = useAboutpageData()
  const lifeData = {
    travelTitle: body[0].items[0].title.text,
    travelContent: body[0].items[0].content.text,
    travelQuote: body[1].primary.quote.text,
  }

  return pug`
    div.relative(className=classnames(oneColumnStyle.wrapper, props.className))
      // Brief
      div.flex.items-center.justify-center
        div.flex.items-start.justify-start.flex-wrap.col-12.col-lg-10.px-0
          h5.text-theme-100.font-bold.mb-8(className=oneColumnStyle.heading)=lifeData.travelTitle
          p.text-theme-100.text-xl.font-semibold.mb-4(className="md:text-2x")=lifeData.travelContent
          p.text-theme-200.text-xl.italic=lifeData.travelQuote
      ImageGrid

  `
}

export default AboutPageLife
