import React from "react"
import classnames from "classnames/bind"
// Component
import SpotifyPlayer from "../../components/about/spotify"
import { OneColumnHeading } from "../../components/project/one-column"
// Style
import oneColumnStyle from "../../style/components/projects/one-column.module.scss"
import experienceStyle from "../../style/section/aboutpage/experience.module.scss"
// Date
import useAboutpageData from "../../hook/aboutpage-data"

function AboutPagePlaylist(props) {
  const { body1 } = useAboutpageData()
  const musicData = {
    musicTitle: body1[0].primary.heading.text,
    musicContent: body1[0].items[0].content.text,
    musicQuote: body1[1].primary.quote.text,
  }

  return pug`
    div.relative(className=experienceStyle.wrapper)
      div.flex.justify-center.items-center.flex-col
       div.flex.items-start.justify-start.flex-wrap.col-12.col-lg-10.px-0
        OneColumnHeading.mb-16(heading=musicData.musicTitle content=musicData.musicContent darkmode=true)
        p.text-theme-200.text-xl.italic.mb-8=musicData.musicQuote
        div.row
          div.col-12.col-md-6.mb-10
            SpotifyPlayer(title="my playlist" src="https://open.spotify.com/embed/playlist/3L4U8ihrOlOdn7oCkWnb2z" width="100%" height="464")
          div.col-12.col-md-6.mb-10
            // 設計遊牧
            SpotifyPlayer(title="design nomads" src="https://open.spotify.com/embed-podcast/show/1AKu7TfNw2Jxtiapm2PIJP" width="100%" height="232")
            //- Google
            //- SpotifyPlayer(src="https://open.spotify.com/embed-podcast/show/0cIzGlabCGYsPz333wTmtK" width="100%" height="232")
            // Design Better
            SpotifyPlayer(title="design better" src="https://open.spotify.com/embed-podcast/show/59RliaMdeDAkEgp9nj1Mkj" width="100%" height="232")

  `
}

export default AboutPagePlaylist
