import React, { useRef, useEffect, useState, useCallback } from "react"
import classnames from "classnames/bind"
import PropTypes from "prop-types"
import throttle from "lodash/throttle"
// Style
import scrollBoxStyle from "../../style/components/about/scroll-box.module.scss"

/**
 * Make a Horizontal Scroll draggable
 * https://github.com/murilovarela/easy-scroll-box
 */

const timing = (1 / 60) * 1000
const decay = v => -0.1 * ((1 / timing) ^ 4) + v

const ScrollBox = ({ children }) => {
  const scrollWrapperRef = useRef(null)
  const scrollWrapper = scrollWrapperRef.current
  const [clickStartX, setClickStartX] = useState()
  const [scrollStartX, setScrollStartX] = useState()
  const [isDragging, setIsDragging] = useState(false)
  const [direction, setDirection] = useState(0)
  const [momentum, setMomentum] = useState(0)
  const [lastScrollX, setLastScrollX] = useState(0)
  const [speed, setSpeed] = useState(0)
  const handleLastScrollX = useCallback(
    throttle(screenX => {
      setLastScrollX(screenX)
    }, timing),
    []
  )
  const handleMomentum = useCallback(
    throttle(nextMomentum => {
      setMomentum(nextMomentum)
      scrollWrapper.scrollLeft += nextMomentum * timing * direction
    }, timing),
    [scrollWrapper, direction]
  )
  useEffect(() => {
    if (direction !== 0) {
      if (momentum > 0.1 && !isDragging) {
        handleMomentum(decay(momentum))
      } else if (isDragging) {
        setMomentum(speed)
      } else {
        setDirection(0)
      }
    }
  }, [momentum, isDragging, speed, direction, handleMomentum])

  useEffect(() => {
    if (scrollWrapper) {
      const handleDragStart = e => {
        setClickStartX(e.screenX)
        setScrollStartX(scrollWrapper.scrollLeft)
        setDirection(0)
      }
      const handleDragMove = e => {
        e.preventDefault()
        e.stopPropagation()

        if (clickStartX !== undefined && scrollStartX !== undefined) {
          const touchDelta = clickStartX - e.screenX
          scrollWrapper.scrollLeft = scrollStartX + touchDelta

          if (Math.abs(touchDelta) > 1) {
            setIsDragging(true)
            setDirection(touchDelta / Math.abs(touchDelta))
            setSpeed(Math.abs((lastScrollX - e.screenX) / timing))
            handleLastScrollX(e.screenX)
          }
        }
      }
      const handleDragEnd = () => {
        if (isDragging && clickStartX !== undefined) {
          setClickStartX(undefined)
          setScrollStartX(undefined)
          setIsDragging(false)
        }
      }

      if (scrollWrapper.ontouchstart === undefined) {
        scrollWrapper.onmousedown = handleDragStart
        scrollWrapper.onmousemove = handleDragMove
        scrollWrapper.onmouseup = handleDragEnd
        scrollWrapper.onmouseleave = handleDragEnd
      }
    }
  }, [
    scrollWrapper,
    clickStartX,
    isDragging,
    scrollStartX,
    handleLastScrollX,
    lastScrollX,
  ])

  return pug`
    div(className=scrollBoxStyle.scrollBox)
      div(className=scrollBoxStyle.scrollBox_wrapper ref=scrollWrapperRef)
        div(className=classnames(scrollBoxStyle.scrollBox_container,isDragging ? scrollBoxStyle.grabbing : scrollBoxStyle.grab))
          each item,index in children
            // div(className=scrollBoxStyle.scrollBox_item key="scrollBox-item-"+index)
            ${item}

  `
}

ScrollBox.propType = {
  children: PropTypes.node.isRequired,
}

export default ScrollBox
