import React from "react"
import Img from "gatsby-image"
import classnames from "classnames/bind"
// Data
import useAboutpageData from "../../hook/aboutpage-data"
// Style
import imageGridStyle from "../../style/components/about/image-grid.module.scss"

const ImageGrid = props => {
  const { life_photo } = useAboutpageData()

  // - BUG:照片內容調整後，照片位置也要調整

  return pug`
    div.mt-12
      div.col-12.px-0
        div.mb-4(className=imageGridStyle.imageWrapper)
          Img(fluid=life_photo[0].life_photo_1.fluid alt=props.image_alt style={ width:'100%' })
      div.col-12.row.px-0
        div.col-8
          div(className=imageGridStyle.imageWrapper_small)
            Img(fluid=life_photo[1].life_photo_1.fluid alt=props.image_alt style={ height:'100%', width:'100%' } imgStyle={ objectPosition: "40% 50%" })
        div.col-4
          div.mb-4(className=imageGridStyle.imageWrapper_small)
            Img(fluid=life_photo[2].life_photo_1.fluid alt=props.image_alt style={ height:'100%', width:'100%' } imgStyle={ objectPosition: "58% 50%" })
      div.col-12.row.px-0
        div.col-4
          div(className=imageGridStyle.imageWrapper_small)
              Img(fluid=life_photo[3].life_photo_1.fluid alt=props.image_alt style={ height:'100%', width:'100%' })
        div.col-8
          div.mb-4(className=imageGridStyle.imageWrapper_small)
            Img(fluid=life_photo[4].life_photo_1.fluid alt=props.image_alt style={ height:'100%', width:'100%' })


  `
}
const EnjoyImageGrid = props => {
  return pug`
    div.pr-6(className=imageGridStyle.enjoyGrid_item key=props.index)
      Img(fluid=props.fluid alt=props.image_alt style={ height:'100%', width:'100%' })
      p.mt-3.txt-caption.text-theme-200.text-center=props.image_alt

  `
}

export { ImageGrid, EnjoyImageGrid }
